<template>
  <div v-if="authUserIsCoach">
    <LoadingMessage v-if="isLoading" :message="'Fetching programs...'"></LoadingMessage>

    <form @submit.prevent v-if="!isLoading" class="d-flex align-items-center bg-dark p-2 rounded">
      <div class="form-floating-cta-container">
        <div class="form-floating-cta">
          <fa-icon
            @click="resetFilter"
            v-if="keyword.length"
            :icon="['fas', 'times-circle']"
            role="button"
          ></fa-icon>
        </div>
        <input
          v-model.trim="keyword"
          ref="keyword"
          type="text"
          class="form-control form-control-dark"
          placeholder="Search program..."
        >
      </div>
      <IconButton
        @click="createProgram"
        :icon="'plus'"
        :isLoading="isCreating"
        :disabled="isCreating"
        class="btn btn-primary ms-2"
      ></IconButton>
      <IconButton
        @click="fetchPrograms"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Name</th>
              <th scope="col" class="align-baseline">Last updated</th>
              <th scope="col" class="align-baseline">Created</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!filteredItems.length">
              <td colspan="4">No programs found</td>
            </tr>
            <ProgramListItem
              @programListItemDelete="deleteProgram"
              v-for="(program, programIndex) in filteredItems"
              :key="`key-${program.programId}`"
              :program="program"
              :index="programIndex + 1"
            ></ProgramListItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet, httpPost } from '@/core/http';

export default {
  name: 'ProgramList',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    ProgramListItem: () => import('./ProgramListItem.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    filteredItems() {
      const { keyword } = this;
      if (keyword.length) {
        const q = keyword.toLowerCase();
        return this.programs.filter((item) => item.name.toLowerCase().indexOf(q) >= 0);
      }
      return this.programs;
    },
  },
  methods: {
    async fetchPrograms() {
      this.isLoading = true;
      this.keyword = '';
      try {
        const res = await httpGet('/program');
        this.programs = res.data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async createProgram() {
      this.isCreating = true;
      try {
        const res = await httpPost('/program', { name: 'New program' });
        const program = res.data;
        this.programs.splice(0, 0, program);
        this.$router.push(`/program/${program.programId}`);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isCreating = false;
      }
    },
    deleteProgram(programId) {
      if (!this.programs) return;
      const index = this.programs.findIndex((p) => p.programId === programId);
      if (index > -1) this.programs.splice(index, 1);
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
  },
  data() {
    return {
      isLoading: false,
      isCreating: false,
      programs: [],
      keyword: '',
    };
  },
  mounted() {
    this.fetchPrograms();
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 45%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 25%;
  }
}
</style>
